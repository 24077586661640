import React, { useRef } from 'react'
import ReactPlayer from 'react-player'

type VideoReactPlayerProps = {
  videoUrl: string
  hasControls: boolean
  isLooping: boolean
  isMuted: boolean
  isAutoPlaying: boolean
  handleOnReady?: () => void
  handleOnStart?: () => void
}

// This video library will detect witch streaming service is used form the videoUrl and load the proper player
export const VideoReactPlayer: React.FC<VideoReactPlayerProps> = ({
  videoUrl,
  hasControls,
  isLooping,
  isMuted,
  isAutoPlaying,
  handleOnReady,
  handleOnStart,
}) => {
  const player = useRef<ReactPlayer>(null)

  return (
    <div className="reactPlayerFullScreenWrapper">
      {/* Responsive video player - Player ratio: 100 / (1280 / 720) - Padding top on the wrapper is needed - https://www.npmjs.com/package/react-player */}
      <div className="reactPlayerFullScreenChild">
        <div className="screen"></div>
        <ReactPlayer
          style={{ position: 'absolute', top: 0, left: 0 }}
          ref={player}
          url={videoUrl}
          width={'100%'}
          height={'100%'}
          loop={isLooping}
          volume={isMuted ? 0 : 1}
          controls={hasControls}
          muted={isMuted}
          playing={isAutoPlaying}
          onReady={handleOnReady && handleOnReady}
          onStart={handleOnStart && handleOnStart}
        />
      </div>
    </div>
  )
}

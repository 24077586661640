import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText, PrismicText, SliceZone } from '@prismicio/react'
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { Layout } from '../components/Layout'
import { components } from '../slices'
import { ProjectHeaderFullScreen } from '../components/ProjectHeaderFullScreen'
import { Parallax } from 'react-scroll-parallax'
import { GatsbyImage } from 'gatsby-plugin-image'

type ProjectTemplateProps = {
  data?: any
}

const ProjectTemplate: React.FC<ProjectTemplateProps> = ({ data }) => {
  const breakpoints = useBreakpoint()
  if (!data) return null

  const projectContent = data.prismicProject
  const project = projectContent.data || {}

  const { lang, type, url } = projectContent
  const alternateLanguages = project.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  const topMenu = data.prismicTopMenu || {}
  const title = project.title.text || 'Untitled'

  console.log(
    'project.project_description.richText.length ',
    project.project_external_link.text,
  )

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <div className="pageWrapper">
        <div className="fullScreen_content">
          <div className="fullScreen_img">
            <GatsbyImage
              image={project.project_background_image?.gatsbyImageData}
              alt={'Project background image'}
            />
          </div>

          <Link to={project.project_external_link.text} target="_blank">
            <div
              className={`projectFullScreenHeader ${
                breakpoints.sm ? 'mobile' : ''
              }`}
            >
              <div className="projectFullScreenHeaderCopy">
                <div className="copyContent">
                  <h2>{title}</h2>
                  {project.project_subtitle.richText.length > 0 && (
                    <PrismicRichText
                      field={project.project_subtitle.richText}
                    />
                  )}
                  <h6>Listen here +</h6>
                </div>
                <div className="imageContent">
                  {project.project_image_info && (
                    <div className="image">
                      <GatsbyImage
                        image={project.project_image_info?.gatsbyImageData}
                        alt={'Project info image image'}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="projectFullScreenHeaderImg">
                {/* {project.project_video_link.text && (
                  <div className="video">
                    <ProjectHeaderFullScreen
                      videoUrl={project.project_video_link.text}
                      fullScreenImage={project.project_thumbnail}
                    />
                  </div>
                )} */}
                {project.project_thumbnail && (
                  <div className="image">
                    <GatsbyImage
                      image={project.project_thumbnail?.gatsbyImageData}
                      alt={'Project thumbnail image'}
                    />
                  </div>
                )}
              </div>
            </div>
          </Link>
        </div>
        <div className="mediasWrapper">
          <SliceZone slices={project.body} components={components} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProjectQuery($id: String) {
    prismicProject(id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
      data {
        date
        title {
          text
        }
        project_subtitle {
          richText
        }
        project_thumbnail {
          gatsbyImageData
          alt
          copyright
        }
        project_image_info {
          gatsbyImageData
          alt
          copyright
        }
        project_background_image {
          gatsbyImageData
          alt
          copyright
        }
        project_external_link {
          text
        }
        project_video_link {
          text
        }
        project_description {
          richText
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...ProjectDataBodyQuote
          ...ProjectDataBodyImageWithCaption
          ...ProjectDataBodyVideo
          ...ProjectDataBodyText
          ...ProjectDataCreditsList
          ...ProjectDataImagesGrid
          ...ProjectDataImagesSlideshow
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
  }
`

export default withPrismicPreview(ProjectTemplate as any)

import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText, SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'
import { Parallax } from 'react-scroll-parallax'
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

type PageTemplateProps = {
  data?: {
    prismicPage?: any
    prismicTopMenu?: any
  }
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  const breakpoints = useBreakpoint()
  if (!data) return null

  const pageContent = data.prismicPage
  const page = pageContent.data || {}

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicTopMenu || {}
  const title = page.page_title.text || 'Untitled'

  const isPageClip = pageContent.id === '9da32159-57bb-5d4f-8e64-fbd2f922af21'

  console.log('page_content_blocs ', pageContent)

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <div
        className={`pageWrapper ${breakpoints.sm ? 'mobile' : ''} ${
          breakpoints.md ? 'medium' : ''
        }`}
      >
        <div className="fullScreen_content">
          <div className="fullScreen_img">
            <GatsbyImage
              image={page.page_images[0].page_image.gatsbyImageData}
              alt={'Page background image'}
            />
          </div>
          <div className="pageFullScreenHeader">
            <div className="wrapper">
              {page.page_content_blocs.map((bloc: any, index: number) => {
                return (
                  <div className={`pageBloc`} key={'page_bloc_' + index}>
                    {bloc.content_bloc_title.text && (
                      <h6>{bloc.content_bloc_title.text}</h6>
                    )}
                    {bloc.content_bloc_desc.richText.length > 0 && (
                      <PrismicRichText
                        field={bloc.content_bloc_desc.richText}
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className={`pageMediasWrapper ${isPageClip && 'clips'} `}>
          <SliceZone slices={page.body} components={components} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query pageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        page_title {
          text
        }
        page_description {
          richText
        }
        page_content_blocs {
          content_bloc_title {
            text
          }
          content_bloc_desc {
            richText
          }
        }
        page_images {
          page_image {
            gatsbyImageData
            alt
            copyright
          }
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...PageDataBodyFullWidthImage
          ...PageDataBodyTextInfo
          ...PageDataBodyVideo
          ...PageDataPageCreditsList
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
  }
`

export default withPrismicPreview(PageTemplate as any)
// export default PageTemplate;
